<script setup lang="ts">
import { useSSRContext, Fragment, getServerState, useRoute } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { type Page } from '@ocp-zmarta/content-sdk'
import { isCustomCampainPage } from '@/helpers'

const ctx = import.meta.env.SSR
  ? useSSRContext()
  : ({
      brand: getServerState('brand'),
      market: getServerState('market'),
      serviceUrls: undefined,
    })

// Define the props
const { pageData } = defineProps<{
  pageData?: Page
}>()

const hreflangs = pageData?.seo?.hreflangs ?? []
const sections = pageData?.content?.sections ?? []

// Get the footer background settings from the last section
const footerBgSettings = sections.at(-1)?.backgroundSettings ?? {}
const { backgroundColor: footerBackgroundColor = '' } = footerBgSettings

// navigation URLs
const siteNavigationHeader = ctx?.serviceUrls
  ? `${ctx?.serviceUrls.siteNavigation}/header`
  : ''

let siteNavigationFooter = ctx?.serviceUrls
  ? `${ctx.serviceUrls.siteNavigation}/footer`
  : ''

if (siteNavigationFooter) {
  if (isCustomCampainPage(useRoute())) {
    siteNavigationFooter += '?bg-color=brand-100&border-color=white&border=clouds&flipped-x=true&flipped-y=true'
  } else if (footerBackgroundColor) {
    siteNavigationFooter += `?bg-color=${footerBackgroundColor}`
  }
}

// Update site navigation header URL if 'en-se' is found in hreflangs
const updatedSiteNavigationHeader = !!siteNavigationHeader &&
    hreflangs.some((item) => item.hreflang === 'en-se')
  ? `${siteNavigationHeader}?show_translations=true`
  : siteNavigationHeader
</script>

<template>
  <div :class="`theme--${ctx?.brand}`">
    <div v-if="!isCustomCampainPage(useRoute())" class="noprint">
      <fragment
        render-mode="server"
        :when-visible="false"
        :url="updatedSiteNavigationHeader ?? ''"
        :headers="{ market: ctx?.market, brand: ctx?.brand }"
      />
    </div>
    <div class="main">
      <slot />
    </div>
    <div class="noprint">
      <fragment
        when-idle
        render-mode="server"
        :url="siteNavigationFooter ?? ''"
        :headers="{ market: ctx?.market, brand: ctx?.brand }"
      />
    </div>
  </div>
</template>

<style scoped>
  .main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: var(--main-page--top-padding, 3.5rem);
  }

  @media print {
    .noprint {
      display: none;
    }

    main {
      padding-top: 0;
    }
  }
</style>
