<script lang="ts" setup>
import type { ExtendedItemSize, Image, Rotate } from '@ocp-zmarta/zmarta-components'
import type { PropType } from 'vue'
import { defineAsyncComponent } from 'vue'
const ZgSection = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgSection))
const ZgIllustration = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgIllustration))
const ZgImage = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgImage))
const ZgButton = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgButton))

interface Content {
  title: string
  priceList: Array<{ label: string, value: string }>
}

interface Button {
  link: string
  label: string
  value: string
  icons: {
    left?: string
    leftRotation?: Rotate
    right?: string
    rightRotation?: Rotate
  }
  fullWidthMobile?: boolean
  size?: ExtendedItemSize
}

defineProps({
  json: {
    type: Object as PropType<{
      title: string
      subtitle: string
      image?: Image
      illustration: string
      before: Content
      after: Content
      savings: { label: string, value: string }
      button?: Button
      disclaimer?: string
    }>,
    required: true,
  },
})

</script>

<template>
  <zg-section>
    <div
      :class="['zg-how-we-helped', {
        'zg-how-we-helped--with-image': json?.image
      }]"
    >
      <div v-if="json?.image || json?.illustration" class="image">
        <zg-image
          v-if="json?.image"
          :src="json?.image.src"
          :title="json?.image.title"
          :alt="json?.image.alt"
          :sources="json?.image.sources"
          lazy-load
        />

        <zg-illustration v-else-if="json?.illustration" :illustration="json?.illustration" />
      </div>

      <div class="content">
        <h3>
          {{ json?.title }}
        </h3>

        <p>
          {{ json?.subtitle }}
        </p>

        <div class="comparison">
          <div class="comparison__labels">
            <div class="values">
              <div v-for="(item, index) in json?.before.priceList" :key="index">
                <span>{{ item.label }}</span>
              </div>
            </div>
          </div>

          <div class="comparison__before">
            <h4>{{ json?.before.title }}</h4>

            <div class="values">
              <div v-for="(item, index) in json?.before.priceList" :key="index">
                <span>{{ item.label }}</span>
                <span>{{ item.value }}</span>
              </div>
            </div>
          </div>

          <div class="comparison__after">
            <h4>{{ json?.after.title }}</h4>

            <div class="values">
              <div v-for="(item, index) in json?.after.priceList" :key="index">
                <span>&nbsp;</span>
                <span>{{ item.value }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="savings">
          <span>{{ json?.savings.label }}</span>
          <span>{{ json?.savings.value }}
            <template v-if="json?.disclaimer">*</template>
          </span>
        </div>

        <a
          v-if="json?.button?.link && json?.button?.label"
          :href="json?.button.link"
          :text="json?.button.label"
          :class="['link', {
            'link--full-width-mobile': true
          }]"
        >
          <zg-button
            v-if="json?.button"
            :text="json?.button.label"
            :button-props="{
              icons: { right: 'arrow-forward' },
              fullWidthMobile: true,
              size: 'xlarge'
            }"
            class="button"
          >{{ json?.button.label }}</zg-button>
        </a>

        <div v-if="json?.disclaimer" class="disclaimer">
          <span>*</span>
          <span>{{ json?.disclaimer }}</span>
        </div>
      </div>
    </div>
  </zg-section>
</template>

<style lang="scss" scoped>
  .zg-how-we-helped {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rhythm();

    @include mq(medium) {
      flex-direction: row;
      gap: rem(38px);
    }

    .image {
      width: auto;
      height: rem(160px);

      @include mq(medium) {
        width: rem(320px);
        height: auto;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: rhythm();
    }

    h3 {
      margin: 0;

      @include mq(medium) {
        margin: 0;
      }
    }

    p {
      margin: 0;

      @include mq(medium) {
        margin: 0;
      }
    }

    .comparison {
      display: flex;
      flex-direction: row;

      @include mq(medium) {
        align-items: flex-end;
      }

      &__labels,
      &__before,
      &__after {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: rhythm(xsmall) 0;
        border-radius: radius(medium);

        @include mq(medium) {
          width: calc(100% / 3);
        }

        h4 {
          @include type-title-m;

          padding: rhythm(small);
          margin: 0;
        }

        .values {
          display: flex;
          flex-direction: column;
          margin-top: auto;

          &>div {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            flex-grow: 1;
            border-bottom: 1px solid var(--gray-200);
            padding: rem(18px) rhythm(small);
            margin: 0;

            @include mq(medium) {
              flex-direction: row;
              align-items: baseline;
              justify-content: flex-start;
            }

            &:last-child {
              border: none;
            }
          }
        }
      }

      &__labels {
        display: none;
        background-color: transparent;

        @include mq(medium) {
          display: flex;
        }

        span {
          @include type-title-s;

          margin: 0;
        }
      }

      &__before,
      &__after {
        span {
          &:first-child {
            @include type-title-xs;

            margin: 0 0 rem(18px);

            @include mq(medium) {
              display: none;
            }
          }

          &:last-child {
            @include type-body-m;

            margin: 0;
          }
        }

        .values {
          &>div:last-child {
            span:first-child {
              @include type-title-xs;

              margin: 0 0 rem(18px);
            }
          }
        }
      }

      &__before {
        background-color: transparent;
      }

      &__after {
        background-color: var(--beige);
      }
    }

    .savings {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      gap: rhythm(small);

      @include mq(medium) {
        flex-direction: row;
      }

      span {
        &:first-child {
          @include type-title-m;

          margin: 0;
        }

        &:last-child {
          @include type-title-xl;

          margin: 0;
        }
      }
    }

    .button {
      border: none;
    }

    .disclaimer {
      @include type-body-s;

      display: flex;
      flex-direction: row;
      color: var(--gray-500);
      margin: 0;

      span:first-child {
        margin-right: rem(2px);
      }
    }

    .link {
      display: inline-block;
      border-bottom: none;

      &--full-width {
        width: 100%;
      }

      &--full-width-mobile {
        @include mq($until: medium) {
          width: 100%;
        }
      }
    }
  }
</style>
