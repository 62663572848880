export default [
  {
    path: 'content.sections',
    merge: true,
    data: {
      componentRef: 'TrustQuoteSection',
      contentId: 'U5ZPWwni7agLO22J7sqLF',
      title: 'Vi finns här för dig',
      trustTitle: 'Utmärkt',
      imageSrc: '',
      name: '',
      profession: '',
      quote: 'Ni har smarta handläggare och är ett företag som sätter kunderna först. Ni är pålitliga på alla avseenden och därför kommer jag att rekommendera er till alla mina bekanta!',
    },
  },
  {
    path: 'content.sections',
    replace: true,
    data: {
      contentId: '3rMpLl3KJrmaIDOZwqnjRl',
      componentRef: 'LeadForm',
      title: 'Håll mig uppdaterad',
      text: 'Fyll i din mejladress för att hålla dig uppdaterad med de senaste tipsen för en smartare vardag. Få råd om hur du sparar pengar, hittar det bästa elavtalet för dig och djupdyk ner i vilka försäkringar som just du kan få ut mer av – för den lägsta prislappen.',
      input: {
        label: 'Mejl',
        error: 'Ange en korrekt e-mailadress (ex. namn@mail.com)',
        emailWarningLanguage: 'se',
      },
      buttonText: 'Vidare',
      titleCompleted: 'Tack för att du har signat upp dig till Zmartas nyhetsbrev!',
      textCompleted: 'Vi kommer att skicka ett bekräftelsemejl till dig inom 24 timmar.',
      titleCompletedFailed: 'Något gick fel',
      textCompletedFailed: 'Vi kunde inte skicka din bekräftelse. Försök igen senare eller kontrollera att din e-postadress är korrekt.',
      disclaimer: 'Genom att klicka “Vidare” bekräftar du att du tagit del av <a href="https://www.zmarta.se/avtal/dataskyddspolicy">Zmartas dataskyddspolicy</a>.',
      backgroundElement: true,
    },
  }]
