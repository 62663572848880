// usePageData.ts
import { ref, onServerPrefetch, onBeforeMount } from 'vue'
import { addServerState, getServerState, useRoute, getLogger, setStatusCode } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import type { Brand, ZmartaAnalytics } from '@ocp-zmarta/content-sdk'
import {
  getPage, type Page, useZmartaAnalytics, type Site, type Market, AvailableSites, type CustomData,
  ErrorPageUrls, HttpStatusCode,
} from '@ocp-zmarta/content-sdk'
import type { SSRContext } from 'vue/server-renderer'
import { isExcludedRoute } from '../../utils/routes-to-exclude'
import { defineSlugs } from '../../utils/define-slugs'

interface PageDataOptions {
  ctx?: SSRContext
  customData?: CustomData[]
}

function setErrorPageStatusCodeFor (pagePath: ErrorPageUrls) {
  switch (pagePath) {
    case ErrorPageUrls.NotFound:
      setStatusCode(HttpStatusCode.NotFound)
      break
    case ErrorPageUrls.InternalServerError:
      setStatusCode(HttpStatusCode.InternalServerError)
      break
  }
}

export default function ({ ctx, customData }: PageDataOptions = {}) {
  const route = useRoute()
  const pageData = ref<Page>()
  const site = ref<Site>()
  const fragmentCustomQuery = {
    locale: ctx?.locale,
    op_user_id: ctx?.userId,
    ip: ctx?.userIp,
  }

  const ZGA = ref<ZmartaAnalytics>()
  const logger = getLogger()

  if (isExcludedRoute(route?.pathname ?? '')) return {}

  onServerPrefetch(async () => {
    const path = route?.pathname ?? ''
    site.value = `${ctx?.brand}.${ctx?.market}` as Site

    if (!path) logger.error('Path is undefined')
    if (!site.value) logger.error('Site is undefined')

    try {
      pageData.value = await getPage({ path, site: site.value, locale: ctx?.locale }, { customData })

      // Set the values on the server state
      addServerState('page-data', pageData.value)
      addServerState('site', site.value)
      addServerState('query', ctx?.query)
    } catch (error: any) {
      setErrorPageStatusCodeFor('/500-error' as ErrorPageUrls)

      ;['serviceUrls', 'locales', 'config', 'cookies'].forEach(key => delete ctx?.[key])
      delete ctx?.headers?.cookie
      logger.error({ error, ctx }, 'Error in getPage fetcher')
    }

    setErrorPageStatusCodeFor(pageData.value?.path as ErrorPageUrls)
  })

  onBeforeMount(() => {
    const market: Market = ctx?.market
    const brand: Brand = ctx?.brand

    // Retrieve values from the server state
    pageData.value = getServerState('page-data')!
    site.value = getServerState('site') ?? '' as Site

    if (AvailableSites.includes(site.value)) {
      ZGA.value = useZmartaAnalytics({
        brand,
        market,
      })

      const serverState: { url: string | undefined, previousUrl: string | undefined } = getServerState('query')!
      const url = serverState.url ?? route?.pathname
      const previousUrl = serverState.previousUrl ?? ''

      if (url === ErrorPageUrls.NotFound) {
        void ZGA.value.event.page?.error404Page({ url, previousUrl })
      }
    }

    const slugs = defineSlugs({ market, data: pageData.value, config: getServerState('config') })
    sessionStorage.setItem('zmarta_session', JSON.stringify({ zmartaSession: { slugs } }))
  })

  return {
    pageData,
    site,
    fragmentCustomQuery,
  }
}
