<script setup lang="ts">
import type { BackgroundColorsTypes, BorderType } from '@ocp-zmarta/zmarta-components'
import { getServerState, useRoute, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { defineAsyncComponent } from 'vue'

const ctx = import.meta.env.SSR
  ? useSSRContext()
  : {
      isElskling: getServerState('isElskling'),
    }

// Components
const ZgBorder = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then((c) => c.ZgBorder))

const route = useRoute()
const getSearchParam = (param: string) => route?.searchParams.get(param)

const backgroundColor = getSearchParam('bg-color') || undefined
const borderColor = getSearchParam('border-color') || undefined
const border = getSearchParam('border') || undefined
const flippedX = !!getSearchParam('flipped-x')
const flippedY = !!getSearchParam('flipped-y')
</script>

<template>
  <div class="border">
    <div
      :class="[
        'spacer',
        {
          'spacer--white': backgroundColor === 'white',
          'spacer--beige': backgroundColor === 'beige',
        },
      ]"
    />

    <zg-border
      :border="(border as BorderType) ?? (ctx?.isElskling ? 'clouds' : 'footer')"
      :background-color="(backgroundColor as BackgroundColorsTypes)"
      :border-color="(borderColor as BackgroundColorsTypes) ?? 'brand-100'"
      :flipped-x="flippedX"
      :flipped-y="flippedY"
    />
  </div>
</template>

<style lang="scss" scoped>
  .border {
    .spacer {
      height: rhythm(double);

      &--white {
        background-color: var(--white);
      }

      &--beige {
        background-color: var(--beige);
      }
    }
  }
</style>
