<script setup lang="ts">
import { Brand, MainPage, type CustomData } from '@ocp-zmarta/content-sdk'
import useFormattedPageData from '@/composables/useFormattedPageData'
import App from '@/components/layout/App.vue'
import { defineAsyncComponent, hydrateOnVisible, useSSRContext, type Component } from 'vue'
import customDataZmarta from '@/components/custom/zmarta/startCompany/custom-data'
import customDataElskling from '@/components/custom/elskling/custom-data'
import { getServerState } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'

const ctx = import.meta.env.SSR
  ? useSSRContext()
  : {
      market: getServerState('market'),
      brand: getServerState('brand'),
      locale: getServerState('locale'),
      userId: getServerState('userId'),
      userIp: getServerState('userIp'),
      isMobile: getServerState('isMobile'),
    }

let customComponents: Record<string, Component> | undefined
let customData: CustomData[] = []
const rootMargin = { rootMargin: '150px' }

switch (ctx?.brand) {
  case Brand.Zmarta: {
    customData = customDataZmarta

    customComponents = {
      TrustQuoteSection: defineAsyncComponent({
        loader: async () => await import('@/components/custom/zmarta/startCompany/TrustQuoteSection.vue'),
        hydrate: hydrateOnVisible(rootMargin),
      }),
      LeadForm: defineAsyncComponent({
        loader: async () => await import('@/components/custom/zmarta/startCompany/LeadForm.vue'),
        hydrate: hydrateOnVisible(rootMargin),
      }),
    }
    break
  }
  case Brand.Elskling: {
    customData = customDataElskling

    customComponents = {
      HowWeHelped: defineAsyncComponent({
        loader: async () => await import('@/components/custom/elskling/HowWeHelped.vue'),
        hydrate: hydrateOnVisible(rootMargin),
      }),
      Otovo: defineAsyncComponent({
        loader: async () => await import('@/components/custom/elskling/Otovo.vue'),
        hydrate: hydrateOnVisible(rootMargin),
      }),
    }
    break
  }
}

const { pageData, site, fragmentCustomQuery } = useFormattedPageData({ ctx, customData })
</script>

<template>
  <app :page-data="pageData">
    <main-page
      v-if="pageData && site"
      :is-mobile="ctx?.isMobile"
      :locale="ctx?.locale"
      :page="pageData"
      :site="site"
      :custom-components="customComponents"
      :fragment-custom-query="fragmentCustomQuery"
    />
  </app>
</template>

<style scoped lang="scss">
  :deep(.zg-text-section__body) {
    h1:not(:first-child) {
      font-size: 2.5rem !important;
      margin: rhythm(small) 0 rhythm(medium) 0 !important;

      @include mq(large) {
        font-size: 3.5rem !important;
      }
    }
  }
</style>
