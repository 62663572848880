import { getServerState } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'

const translations = {
  se: {
    $locale: ['sv-SE', 'sv', 'en-SE'],
  },
  no: {
    $locale: ['nb-NO', 'nb'],
  },
  fi: {
    $locale: ['fi-FI', 'fi'],
  },
}

export function useLocale (): string[] | undefined {
  const market = getServerState<string>('market')
  if (market == null || !(market in translations)) throw new Error('Unknown market')
  return translations[market as keyof typeof translations].$locale
}

export const isCustomCampainPage = (route?: { pathname: string }): boolean => {
  if (!route) return false

  const paths = ['/clp/lan/custom', '/clp/forsakring/custom']
  const pathname = route?.pathname ?? ''
  return paths.some(path => pathname.startsWith(path))
}
